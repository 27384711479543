<template>
  <div v-if="loading">Cargando...</div>

  <div v-else>
    <div v-for="(section, index) in pageConfig.sections" :key="index">
      <component
        :is="getComponentType(section.type)"
        :content="section.content"
      />
    </div>
  </div>
</template>

<script>
import HeaderSection from "./pageRender/HeaderSection.vue";
import TextSection from "./pageRender/TextSection.vue";
import GallerySection from "./pageRender/GallerySection.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    HeaderSection,
    TextSection,
    GallerySection,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },
  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel, oldWhitelabel) {
        if (newWhitelabel?._id !== oldWhitelabel?._id)
          this.$router.push({ name: "PageManager" });
      },
    },
  },
  data() {
    return {
      pageConfig: null,
      loading: true,
      example: {
        title: "Mi primera página",
        sections: [
          {
            type: "header",
            content: "Bienvenido a mi página",
          },
          {
            type: "gallery",
            images: ["url1.jpg", "url2.jpg"],
          },
          {
            type: "text",
            content: "Este es un texto de ejemplo.",
          },
        ],
      },
    };
  },
  methods: {
    async fetchPageData() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/pages/${this.id}`
        );
        this.pageConfig = response.data;
      } catch (error) {
        console.error("Error al obtener la página:", error);
      } finally {
        this.loading = false;
      }
    },

    getComponentType(type) {
      const map = {
        header: "HeaderSection",
        text: "TextSection",
        gallery: "GallerySection",
      };
      return map[type] || "div";
    },
  },

  created() {
    this.fetchPageData();
  },
};
</script>
